//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionTipoAso = ({ commit }) => (
        http.get('/tipoaso').then(resp => {
            commit(types.SET_TIPOASO, resp.data)
        })
)


export const ActionFindByExame = ( {commit}, payload) => (
    http.get('/exame/'+payload.id).then(resp => {
        commit(types.SET_ASO, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
 //console.log(payload)

  http.post('/aso',payload)
}

export const ActionEditar= (context, payload) => {

 // delete payload.especialidademedica;
  http.put('/aso/'+payload.id, payload)

}

export const ActionDeletar= (context, payload) => {

  http.delete('/aso/'+payload.id+'/'+payload.user_exclusao)
}

