//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindMedico = ({ commit }) => (

        http.get('/medico').then(resp => {

            commit(types.SET_MEDICOS, resp.data)
       
          })
)




export const ActionSalvar = (context, payload) => {
  http.post('/medico',payload)
}


export const ActionSalvarFechamento = (context, payload) => {
  http.post('/medico/fechamentomedico',payload)
}


export const ActionEditarFechamento= (context, payload) => {
  http.put('/medico/fechamentomedico/'+payload.id, payload)
}


export const ActionEditar= (context, payload) => {
  http.put('/medico/'+payload.id, payload)
}


export const ActionDeletar= (context, payload) => {
  http.delete('/medico/'+payload)
}

