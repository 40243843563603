import axios from "axios";
import store from '@/store'

export const http = axios.create({
   baseURL: `${process.env.VUE_APP_HTTP}`,
   //baseURL:'https://www.tceto.tc.br/backend',
    headers: {
        'Content-type': 'application/json; charset=utf-8', 
        'Accept': 'application/json'
    }
})

export const local = axios.create({
   baseURL: 'http://localhost:1024/frontemsclinica/',
   //baseURL:'https://www.tceto.tc.br/backend',
    headers: {
        'Content-type': 'application/json; charset=utf-8', 
        'Accept': 'application/json'
    }
    
})

//intercepta as requests
http.interceptors.request.use(config=>{
  return config
}, error => Promise.reject(error))

// //intercepta os response api
http.interceptors.response.use(resp=>{

  if (resp.status === 401){//status nao autorizado obs: tratar os demais erros
    store.dispatch('auth/ActionSignOut')//remove o token do header
    window._Vue.$router.push({ name: 'login' })//redireciona para o login
  }

  if (resp.status === 400){//status nao autorizado obs: tratar os demais erros
    store.dispatch('auth/ActionSignOut')//remove o token do header
    window._Vue.$router.push({ name: 'login' })//redireciona para o login
  }
  return resp
}, error => Promise.reject(error))

//seta o token no header para as proximas requests
const setBearerToken = access_token => {
  http.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
 }

 export{setBearerToken}