export default [
  {
    path: '/frontemsclinica/empresa',
    name: 'empresa',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'grid1x2', 
      title: 'Empresas',
      label: 'Empresas'
    },
    component: () => import(/* webpackChunkName: "ConvenioEmpresa" */ './pages/Empresa'),
  }
]

