//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindFinanceiro = ({ commit }) => (
        http.get('/financeiro').then(resp => {

            commit(types.SET_DADOSFINANCEIROS, resp.data)
        })
)

export const ActionFindByFinanceiro = ( {commit}, payload) => (
    http.get('financeiro/'+payload.id).then(resp => {
        commit(types.SET_DADOSFINANCEIROS, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
  http.post('/financeiro',payload)
}

export const ActionEditar= (context, payload) => {
  http.put('/financeiro/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/financeiro/'+payload)
}

