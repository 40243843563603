export default [
  
  {
    path: '/frontemsclinica/relatorio/recepcao',
    name: 'Relatórios Recepção',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'list-check', 
      title: 'Relatórios',
      label: 'Relatórios'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/Recepcao'),
  },

  {
    path: '/frontemsclinica/relatorio/EdicaoExclusao',
    name: 'Relatório Edição Asos',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'list-check', 
      title: 'Relatórios Edicao',
      label: 'Relatórios Edicao'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/EdicaoExclusao'),
  },

  {
    path: '/frontemsclinica/relatorio/FechamentoFinanceiro',
    name: 'Relatório Financeiro',
    meta: {
      showNavbar: false,
      showDashboard: true,
      icon: 'list-check', 
      title: 'Rel. Fechamento',
      label: 'Rel. Fechamento'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/FechamentoFinanceiro')
  },
  
  {
    path: '/frontemsclinica/relatorio/FechamentoFinanceiroConsolidado',
    name: 'Financeiro Consolidado',
    meta: {
      showNavbar: false,
      showDashboard: true,
      icon: 'list-check', 
      title: 'Rel. Consolidado',
      label: 'Rel. Consolidado'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/FechamentoFinanceiroConsolidado'),
  },
  {
    path: '/frontemsclinica/relatorio/FechamentoMedicos',
    name: 'Rel. Médico',
    meta: {
      showNavbar: false,
      showDashboard: true,
      icon: 'list-check', 
      title: 'Rel. Médicos',
      label: 'Rel. Médicos'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/FechamentoMedicos'),
  } ,
  {
    path: '/frontemsclinica/relatorio/RelatorioExamesConsolidado',
    name: 'Exames Consolidado',
    meta: {
      showNavbar: false,
      showDashboard: true,
      icon: 'list-check', 
      title: 'Tipo Exames-teste',
      label: 'Tipo Exames-teste'
    },
    component: () => import(/* webpackChunkName: "Recepcao" */ './pages/RelatorioExamesConsolidado'),
  } 
]

