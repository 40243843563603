import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'

import datePicker from 'vue-bootstrap-datetimepicker';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'
import VueTheMask from 'vue-the-mask'
import './plugins/axios'
import money from 'v-money'
//import { useCurrencyInput } from 'vue-currency-input'
import VueCookies from 'vue-cookies'
import vSelect from "vue-select";

 import mask2 from '@/helpers/mixins/mask2'
 import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.config.devtools = true
Vue.component("v-select", vSelect);
Vue.use(VueCookies)
//Metodo para chamada de mascaras global
Vue.use(mask2)

// Vue.use(useCurrencyInput)
Vue.use(money, {precision: 4})
Vue.use(VueTheMask)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(datePicker);
Vue.config.productionTip = false

Vue.config.errorHandler = function(err, vm, info) {
  // alert('erro componente')
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}

Vue.config.warnHandler = function(msg, vm, trace) {
  alert('warning componente')
  console.log(`Warn: ${msg}\nTrace: ${trace}`);
}


window._Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

