//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindEmpresa = ({ commit }) => (
        http.get('/empresa').then(resp => {

            commit(types.SET_DADOSEMPRESA, resp.data)
        })
)

export const ActionFindEmpresaCnpj = ({ commit }) => (
        http.get('/empresa').then(resp => {
           
                for(let x in resp.data) {
                  resp.data[x].nome =''+resp.data[x].nome+' - '+resp.data[x].cnpj+'';
                }

            commit(types.SET_DADOSEMPRESACNPJ, resp.data)
        })
)

export const ActionFindByEmpresa = ( {commit}, payload) => (
    http.get('/empresa/'+payload.id).then(resp => {
        commit(types.SET_DADOSEMPRESA, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
  http.post('/empresa',payload)
}

export const ActionEditar= (context, payload) => {

  http.put('/empresa/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/empresa/'+payload)
}

