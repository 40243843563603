
const mask2 = {
  
  install(Vue, options) {
         console.log(options);

    Vue.mixin({
      methods: {

        mascaraCnpj: function (value) {
   
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

       mascaraCpf: function (value) {

       // alert('as')
                       
                            var mascara = (`${value}`).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
                            return mascara;
                  },

        formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
        
        incialMaiuscula: function (value){
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        
        todasMaiusculas: function(value){
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        
      trueParaSim: function (value){
        //  console.log(value)
        return (value==true) ? 'SIM' : 'NÃO' 

        },

        // formatarCpfCnpj: function(documentId) {
        //     documentId = typeof documentId != 'string' ? documentId.toString() : documentId;
           
        //     if (documentId.length > 11 ) {
        //       documentId = documentId.padStart(14, '0');
        //       documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        //     } else {
        //       documentId = documentId.padStart(11, '0');
        //       documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        //     }
        //     return documentId;
        // },


        formatarDataEntradaParaGrid: function (value) {
            if (value === null) { return null }
           // return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })
            var datePart = value.match(/\d+/g),
                  year = datePart[0], // get only two digits
                  month = datePart[1], day = datePart[2];

            return day+'/'+month+'/'+year;
          },

        formatarDataEntradaFormulario: function (value) {
            if (value === null) { return null }

           // return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })
            var datePart = value.match(/\d+/g),
                  year = datePart[0], // get only two digits
                  year2 = datePart[1],
                  day = datePart[2].substring(2),
                  month = datePart[2].substring(0,2);
            return day+'/'+month+'/'+year+year2;
          },
        
        formatarDataParaBanco: function (value) {
         
          if (value === null) { return null }

                const [day, month, year] = value.split('/');
                const result = [year, month, day].join('-');

                return result
         },

        formatarDataParaBancoTmz: function (value) {
         
          if (value === null) { return null }

                //const [day, month, year] = value.split('/');
                const [data, tmz] = value.split('T');
                console.log(tmz)
                const result = data;

                return result
         },


        formatarPhone: function (v) {
            var r = v.replace(/\D/g, "");
            r = r.replace(/^0/, "");
            if (r.length > 10) {
                r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
            } else if (r.length > 5) {
                r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
            } else if (r.length > 2) {
                r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
            } else {
                r = r.replace(/^(\d*)/, "($1");
            }
            return r;
        },

        convertNumberToReal: function(value) {
        
          let resultado;

          let val = (value/1).toFixed(2).replace('.', ',')
          resultado = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

          if(resultado==undefined){
            return value
          }

          if(resultado==''){
                      return value
                    }
           if(resultado=='NaN'){
                      return value
                    }

            return resultado;

          },

        converteMoedaFloat: function(value) {
              
                    if(value === ""){
                            value =  0;
                          }else{
                            value = value.replace(".","");
                            value = value.replace(",",".");
                            value = parseFloat(value);
                          }
                    return value;
          },

        converteFloatMoeda:  function(valor) {

          var inteiro = null, decimal = null, c = null, j = null;
          var aux = new Array();
          valor = ""+valor;
          c = valor.indexOf(".",0);
      //encontrou o ponto na string
          if(c > 0){
            //separa as partes em inteiro e decimal
            inteiro = valor.substring(0,c);
            decimal = valor.substring(c+1,valor.length);
          }else{
            inteiro = valor;
          }
      
      //pega a parte inteiro de 3 em 3 partes
          for (j = inteiro.length, c = 0; j > 0; j-=3, c++){
            aux[c]=inteiro.substring(j-3,j);
          }
          
      //percorre a string acrescentando os pontos
          inteiro = "";
          for(c = aux.length-1; c >= 0; c--){
            inteiro += aux[c]+'.';
          }
      //retirando o ultimo ponto e finalizando a parte inteiro
      
          inteiro = inteiro.substring(0,inteiro.length-1);
          
          decimal = parseInt(decimal);
          if(isNaN(decimal)){
            decimal = "00";
          }else{
            decimal = ""+decimal;
            if(decimal.length === 1){
                decimal = decimal+"0";
            }
          }
      
          valor = inteiro+","+decimal;
          return valor;

        },


        formatNumeroToReal: function(value) {
                    var valor = value;
          
                    valor = valor + '';
                    valor = parseInt(valor.replace(/[\D]+/g,''));
                    valor = valor + '';
                    valor = valor.replace(/([0-9]{2})$/g, ",$1");

                    if (valor.length > 6) {
                      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                    }
                     if (valor=='NaN') {
                      valor = 0;
                    }

                    return valor;
        },




        formatarDataInput: function(e) {

             var v=e.replace(/\D/g, "");
             return v.replace(/(\d{2})(\d)/, "$1/$2").replace(/(\d{2})(\d)/, "$1/$2").replace(/(\d{4})(\d)/, "$1");

            },

      campoAtivo: function(value){

            if (value){
              return 'SIM'
            }

            else{
            return "NÃO"
            }

          },
    
      }
    });
  }
};
export default mask2;