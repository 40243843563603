import * as types from './mutation-types'

export default {
  [types.SET_EXAMESASO] (state, payload) {
    state.tableExamesAso = payload
  },
   [types.SET_ASO] (state, payload) {
    state.tableAso = payload
  },
   [types.SET_TIPOASO] (state, payload) {
    state.tableTipoAso = payload
  },
   [types.SET_TIPOPAGAMENTO] (state, payload) {
    state.tableTipoPagamento = payload
  }
}

