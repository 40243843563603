import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindMedicoExaminador = ({ commit }) => (

        http.get('/medicoexaminador').then(resp => {

            

            commit(types.SET_MEDICOEXAMINADOR, resp.data)
        })
)

export const ActionSalvar = (context, payload) => {
  http.post('/medicoexaminador',payload)
}

export const ActionEditar= (context, payload) => {
  http.put('/medicoexaminador/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/medicoexaminador/'+payload)
}