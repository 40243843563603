export default [
  {
    path: '/frontemsclinica/convenioempresa',
    name: 'convenioempresa',
    query:{
      empresa_id: ''
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'person-lines-fill', 
      title: 'Convênio Empresas',
      label: 'Convênvio Empresas'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/ConvenioEmpresa'),
  }
]

