import store from '@/store'
//import jwt_decode from "jwt-decode";

export default async (to, from, next) => {

  document.title = `${to.name} - ${process.env.VUE_APP_SISTEMA}`

  if (to.name !== 'login' && !store.getters['auth/hasToken']){
    
    try {
            await store.dispatch('auth/ActionCheckToken')
            next({ name: 'login' })

        } catch (err) {
          next({ name: 'login' })
        }

  } else {
          if(to.name === 'login' && store.getters['auth/hasToken']){

             next({ name: 'home' })
         
            } else {
            next()
          }
  }
}