//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindProcedimentos= ({ commit }) => (
        http.get('/procedimentos').then(resp => {

            commit(types.SET_DADOS, resp.data)
        })
)

export const ActionFindByProcedimentos = ( {commit}, payload) => (
    http.get('/procedimentos/'+payload.id).then(resp => {
        commit(types.SET_DADOS, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
  http.post('/procedimentos',payload)
}

export const ActionEditar= (context, payload) => {
  http.put('/procedimentos/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/procedimentos/'+payload)
}

