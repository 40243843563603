export default [
  {
    path: '/frontemsclinica/usuario',
    name: 'Cadastro de Usuarios',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'people-fill', 
      title: 'Usuarios',
      label: 'Usuarios'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/Users'),
  }
]

