export default [
  {
    path: '/frontemsclinica/funcao',
    name: 'Cadastro de Funções',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'layers-fill', 
      title: 'Funções',
      label: 'Funções'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/Funcao'),
  }
]

