import * as types from './mutation-types'

export default {
  [types.SET_DADOS] (state, payload) {
    state.tablePacientes = payload
  },
   [types.SET_DADOSCATEGORIATRABALHADOR] (state, payload) {
    state.tableCategoriaTrabalhadores = payload
  },
   [types.SET_DADOSNACIONALIDADE] (state, payload) {
    state.tableNacionalidade = payload
  }
}