import { routes as home } from '../views/home'
import { routes as auth } from '../views/auth'
import { routes as funcao } from '../views/Funcao'
import { routes as paciente } from '../views/Paciente'
import { routes as empresa } from '../views/Empresa'
import { routes as exame } from '../views/Exame'
import { routes as users } from '../views/Users'
import { routes as convenioempresa } from '../views/ConvenioEmpresa'
import { routes as aso } from '../views/Aso'
import { routes as medico } from '../views/Medico'
import { routes as relatorio } from '../views/Relatorio'
import { routes as fichaavaliacao } from '../views/FichaAvaliacao'
import { routes as financeiro } from '../views/Financeiro'
import { routes as medicoexaminador } from '../views/MedicoExaminador'
export default [
  ...auth,
  ...home,
  ...funcao,
  ...paciente,
  ...empresa,
  ...exame,
  ...users,
  ...convenioempresa,
  ...aso,
  ...medico,
  ...relatorio,
  ...fichaavaliacao,
  ...financeiro,
  ...medicoexaminador
]