//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'

const moment = require("moment");


export const ActionFindPaciente = ({ commit }) => (
        http.get('/paciente').then(resp => {

            (resp.data).forEach(function(item){//tratando as datas 
                item.datanascimento= moment(item.datanascimento).add(1, 'days').format("DD/MM/yyyy")
                item.dataentradaempresa= moment(item.dataentradaempresa).add(1, 'days').format("DD/MM/yyyy")
            })

            commit(types.SET_DADOS, resp.data)
        })
)

export const ActionFindCategoriaTrabalhador = ({ commit }) => (
        http.get('/categoriatrabalhador').then(resp => {
            commit(types.SET_DADOSCATEGORIATRABALHADOR, resp.data)
        })
)

export const ActionFindNacionalidade = ({ commit }) => (
        http.get('/nacionalidade').then(resp => {

            commit(types.SET_DADOSNACIONALIDADE, resp.data)
        })
)



export const ActionFindByPaciente = ( {commit}, payload) => (
    http.get('/paciente/'+payload.id).then(resp => {
        commit(types.SET_DADOS, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
  http.post('/paciente',payload)
}

export const ActionEditar= (context, payload) => {

  http.put('/paciente/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/paciente/'+payload)
}

