//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


// export const ActionFind = ({ commit }) => (
//         http.get('/convenioempresa').then(resp => {
//             commit(types.SET_DADOS_CONVENIO, resp.data)
//         })
// )

//export const ActionFindConvenioByEmpresa = ( {commit}) => (
  //  http.get('/convenioempresa/', payload).then(resp => {
   //     commit(types.SET_DADOS, resp.data)
  //  })
//  http.get('/convenioempresa/').then(resp => {
//        commit(types.SET_DADOS, resp.data)
//    })

//)


export const ActionFindConvenioByEmpresa = ( {commit}, payload) => (

    http.get('/convenioempresa/'+payload.empresa_id).then(resp => {
        commit(types.SET_DADOS_CONVENIO, resp.data)
    })
)



export const ActionSalvar = (context, payload) => {
  http.post('/convenioempresa',payload)
}

export const ActionEditar= (context, payload) => {
  delete payload.exame;
    delete payload.empresa;
  http.put('/convenioempresa/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/convenioempresa/'+payload)
}

