import { store as auth } from '@/views/auth'
import { store as funcao } from '@/views/Funcao'
import { store as paciente } from '@/views/Paciente'
import { store as empresa } from '@/views/Empresa'
import { store as procedimentos } from '@/views/Procedimentos'
import { store as exame } from '@/views/Exame'
import { store as users } from '@/views/Users'
import { store as convenioempresa } from '@/views/ConvenioEmpresa'
import { store as aso } from '@/views/Aso'
import { store as medico } from '@/views/Medico'
import { store as relatorio } from '@/views/Relatorio'
import { store as fichaavaliacao } from '@/views/FichaAvaliacao'
import { store as financeiro } from '@/views/Financeiro'
import { store as medicoexaminador } from '@/views/MedicoExaminador'
export default {
  auth,
  funcao,
  paciente,
  empresa,
  procedimentos,
  exame,
  users,
  convenioempresa,
  aso,
  medico,
  relatorio,
  fichaavaliacao,
  financeiro,
  medicoexaminador
}