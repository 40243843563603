export default [
  {
    path: '/frontemsclinica/medico',
    name: 'Cadastro de Médicos',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'person-badge-fill', 
      title: 'Medicos',
      label: 'Medicos'
    },
    component: () => import(/* webpackChunkName: "Medico" */ './pages/Medico'),
  }
]