export default [
  {
    path: '/frontemsclinica/fichaavaliacao',
    name: 'Ficha de Avaliação',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'layers-fill', 
      title: 'Ficha Avaliação',
      label: 'Ficha Avaliação'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/FichaAvaliacao'),
  }
]

