export default [
  {
    path: '/frontemsclinica/exame',
    name: 'Cadastro de Exames',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'basket-fill', 
      title: 'Exames',
      label: 'Exames'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/Exame'),
  }
]

