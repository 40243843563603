//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'

export const ActionFind = ({ commit }) => (
        http.get('/user').then(resp => {
            commit(types.SET_DADOS, resp.data)
        })
)

export const ActionFindByUser = ( {commit}, payload) => (
        http.get('/user/'+payload.id).then(resp => {
            commit(types.SET_DADOS, resp.data)
        })
)

export const ActionSalvar = (context, payload) => {
       http.post('/user',payload)
}

export const ActionEditar= (context, payload) => {
       http.put('/user/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
       http.delete('/user/'+payload)
}