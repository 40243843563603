import * as types from './mutation-types'

export default {

   [types.SET_DADOSEMPRESA] (state, payload) {
    state.tableEmpresas = payload
  },
  [types.SET_DADOSEMPRESACNPJ] (state, payload) {
    state.tableEmpresasCnpj = payload
  }
}