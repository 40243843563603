export default [
  {
    path: '/frontemsclinica/medicoexaminador',
    name: 'Medico Coordenador',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'person-badge-fill', 
      title: 'Medico Coordenador',
      label: 'Medico Coordenador'
    },
    component: () => import(/* webpackChunkName: "MedicoExaminador" */ './pages/MedicoExaminador'),
  }
]