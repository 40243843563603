export default [
  {
    path: '/frontemsclinica/aso',
    name: 'aso',
    query:{
      paciente_id: ''
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'people', 
      title: 'Asos',
      label: 'Asos'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/Aso'),
  },
  
    {
    path: '/frontemsclinica/DescontoExameAso',
    name: 'descontoaso',
    query:{
      paciente_id:'',
      aso_id: '',
      convenio_empresa:false
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'people', 
      title: 'Desconto Aso',
      label: 'Desconto Aso'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/DescontoExameAso'),
  },
    {
    path: '/frontemsclinica/editaso',
    name: 'editaso',
    query:{
      paciente_id:'',
      aso_id: '',
      convenio_empresa:false
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'people', 
      title: 'Editar Aso',
      label: 'Editar Aso'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/EditarAso'),
  },




{
    path: '/frontemsclinica/uploadexames',
    name: 'uploadexames',
    query:{
      paciente_id:'',
      aso_id: '',
      convenio_empresa:false
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'people', 
      title: 'Upload Exame',
      label: 'Upload Exame'
    },
    component: () => import(/* webpackChunkName: "UploadExames" */ './pages/UploadExames'),
  },

  {
    path: '/frontemsclinica/listaso',
    name: 'listaso',
    meta: {
      showNavbar: true,
      showDashboard: false,
      icon: 'card-list',
      title: 'Lista Asos',
      label: 'Lista Asos'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/ListAsosPesquisa'),
  },
  {
   path: '/frontemsclinica/listasoeditadas',
    name: 'listasoeditadas',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'card-list',
      title: 'Asos Editadas',
      label: 'Asos Editadas'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/ListaAsosEditadas'),
  },
  {
   path: '/frontemsclinica/listasoexcluidas',
    name: 'listasoexcluidas',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'card-list',
      title: 'Asos Excluidas',
      label: 'Asos Excluidas'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/ListaAsosExcluidas'),
  },
  {
   path: '/frontemsclinica/uploads',
    name: 'uploads',
      query:{
      paciente_id:'',
      aso_id: '',
      convenio_empresa:false
    },
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'card-list',
      title: 'Upload-Teste',
      label: 'Upload-Teste'
    },
    component: () => import(/* webpackChunkName: "ListAso" */ './pages/Uploads'),
  }

]

