export default [
  {
    path: '/frontemsclinica/financeiro',
    name: 'Fechamento',
    meta: {
      showNavbar: false,
      showDashboard: false,
      icon: 'newspaper', 
      title: 'Fechamento',
      label: 'Fechamento'
    },
    component: () => import(/* webpackChunkName: "Funcao" */ './pages/FechamentoEmpresas'),
  }
]

