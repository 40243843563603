//import * as storage from '../resource/storage'
import * as types from './mutation-types'
import {http} from '@/plugins/axios'


export const ActionFindFuncao = ({ commit }) => (
        http.get('/funcao').then(resp => {

            commit(types.SET_DADOS, resp.data)
        })
)

export const ActionFindFuncaoCbo = ({ commit }) => (
        http.get('/funcao').then(resp => {
           
                for(let x in resp.data) {
                  resp.data[x].name =''+resp.data[x].name+' - '+resp.data[x].cbo+'';
                }

            commit(types.SET_DADOSCBO, resp.data)
        })
)


export const ActionFindByFuncao = ( {commit}, payload) => (
    http.get('/funcao/'+payload.id).then(resp => {
        commit(types.SET_DADOS, resp.data)
    })
)

export const ActionSalvar = (context, payload) => {
  http.post('/funcao',payload)
}

export const ActionEditar= (context, payload) => {
  http.put('/funcao/'+payload.id, payload)
}

export const ActionDeletar= (context, payload) => {
  http.delete('/funcao/'+payload)
}

